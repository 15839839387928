<template>
  <div>
    <div class="title">吉林音像出版社</div>
    <div class="desc" style="margin-top:19px;">
      &nbsp;&nbsp;&nbsp;&nbsp;吉林音像出版社成立于1995年8月，现有从业人员30人，其中编辑人员16人，编辅人员2人，管理人员12人，该社内设9个编辑部及总编办、发行科、出版科、储运科、审读室、财务科等行政管理和经营共15个部门。
    </div>
    <div class="tg-sectionhead">
      <div>历史沿革</div>
    </div>

    <div class="desc">
      吉林音像出版社成立于1995年8月。
      <br />
      建社9年来，本着"弘扬中国民族文化，发展中国音乐，出精品,创品牌"的出版理念，出版了一批高品位、高质量的音像、图书制品，并初步形成了以出版语音教学带为基础，以中国民族音乐为主要特色的"吉林音像"风格。
    </div>

    <div class="tg-sectionhead">
      <div>主要业务</div>
    </div>
    <div class="desc">
      建社至今，共出版了音像制品217种，相关图书98种，代表性产品有音乐作品《风雪爬犁》（CD）、《冰雪神韵》（CD）、《萨克斯曲选》（CD）等。
    </div>

    <div class="tg-sectionhead">
      <div>荣誉</div>
    </div>
    <div class="desc">
      分别获第七届上海国际广播音乐节国内十佳节目奖，全国优秀文艺音像制品一、三等奖。
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
}
</script>

<style scoped>
.title {
  font-size: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 19px;
}

.tg-sectionhead {
  margin: 30px 0 10px 0;
  padding: 0 0 10px 0;
}

.tg-sectionhead > div {
  border-left: 5px solid #f05000;
  font-size: 16px;
  padding-left: 10px;
}
</style>
